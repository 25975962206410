<template>
  <CModal class="select-new-survey-type-modal" :show.sync="isShownModal" :centered="true" :closeOnBackdrop="false">
    <div class>
      <div class="tit-leave">
        <h4>{{ $t('SELECT_NEW_SURVEY_TYPE_MODAL_COMPONENT_TITLE') }}</h4>
      </div>

      <div class="row mb-2">
        <div class="col-md-12 text-center">
          <CButton class="btn btn-choose-quiz" color="primary" @click="createAQuiz()">{{
            $t('SELECT_NEW_SURVEY_TYPE_MODAL_COMPONENT_CREATE_A_QUIZ_BUTTON')
          }}</CButton>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-md-12 text-center">
          <CButton class="btn btn-choose-survey" color="info" @click="createASurvey()" variant="outline">{{
            $t('SELECT_NEW_SURVEY_TYPE_MODAL_COMPONENT_CREATE_A_SURVEY_BUTTON')
          }}</CButton>
        </div>
      </div>
    </div>

    <template #footer>
      <div class="text-center">
        <CButton class="btn btn-cancel" @click="closeModal()" variant="outline">{{
          $t('SELECT_NEW_SURVEY_TYPE_MODAL_COMPONENT_CANCEL_BUTTON')
        }}</CButton>
      </div>
    </template>
  </CModal>
</template>

<script>
export default {
  name: 'SelectNewSurveyTypeModal',
  props: {
    isShownModal: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    createAQuiz() {
      this.$emit('create-quiz', {});
    },
    createASurvey() {
      this.$emit('create-survey', {});
    },
    closeModal() {
      this.$emit('close-modal', false);
    },
  },
};
</script>

<style lang="scss">
.select-new-survey-type-modal {
  .modal-dialog {
    max-width: 450px;

    .modal-content {
      .modal-header {
        display: none;
      }

      .modal-body {
        padding: 24px 20px 0 20px;

        .tit-leave {
          margin-bottom: 20px;

          h4 {
            font-size: 18px;
            line-height: 21px;
            color: #4a4a4a;
            text-align: center;
          }
        }

        .btn-choose-quiz {
          background: #3b4b7e;
          border: 1px solid #3b4b7e;
          box-sizing: border-box;
          border-radius: 18px;
          color: #ffffff;
          min-width: 50%;
        }

        .btn-choose-survey {
          background: #ffffff;
          border: 1px solid #3b4b7e;
          box-sizing: border-box;
          border-radius: 18px;
          color: #3b4b7e;
          min-width: 50%;
        }
      }

      .modal-footer {
        display: block;
        border-top: none;

        .btn-cancel {
          color: #657187;
          font-size: 14px;
          line-height: 16px;
          text-align: center;
          letter-spacing: 0.5px;
          border-radius: 18px;
          color: #657187;
          min-width: 50%;
        }
      }
    }
  }
}
</style>
