/* eslint-disable vue/no-v-html */
<template>
  <div class="survey-table-container mt-3">
    <CCard class="survey-list-table-card">
      <CDataTable :items="items" :fields="fields" fixed hover striped bordered>
        <template ##="{ item, index }">
          <td>{{ index + 1 }}</td>
        </template>
        <template #status="{ item }">
          <td class="text-left">
            <StakingBadge class="no-border" type="survey-status" :text="item.status" />
          </td>
        </template>
        <template #name="{ item }">
          <td><StakingBadge type="survey-type" :text="item.type" class="mr-2" /> {{ item.name | truncate(30) }}</td>
        </template>
        <template #start_date="{ item }">
          <td>{{ item.start_date | moment(dateFormat) }}</td>
        </template>
        <template #end_date="{ item }">
          <td>{{ item.end_date | moment(dateFormat) }}</td>
        </template>
        <template #duration="{ item }">
          <td>{{ item.duration }}</td>
        </template>
        <template #gold_membership_point="{ item }">
          <td>
            <span class="gold-membership-point">{{ formatNumber(item.gold_membership_point, '-') }}</span>
          </td>
        </template>
        <template #silver_membership_point="{ item }">
          <td>
            <span class="silver-membership-point">{{ formatNumber(item.silver_membership_point, '-') }}</span>
          </td>
        </template>
        <template #platinum_membership_point="{ item }">
          <td>
            <span class="platinum-membership-point">{{ formatNumber(item.platinum_membership_point, '-') }}</span>
          </td>
        </template>

        <template #action="{ item, index }">
          <td class="buttons" width="120px">
            <button class="btn btn-view-details" @click="viewDetails(item,index + 1)">
              <img src="@/assets/images/ico-edit-user.svg" alt class="hand edit-icon" />
            </button>
            <CButton @click="openModal(item)" v-if="isAllowedDel && item.name">
              <img src="@/assets/images/ico-del-user.svg" alt class="hand edit-icon" />
            </CButton>
          </td>
        </template>
      </CDataTable>
    </CCard>
    <CModal id="deleteModal" :show.sync="warningModal" :centered="true">
      <template #header>
        <CButtonClose @click="warningModal = false" />
      </template>
      <div class>
        <div class="pb-4 tit-dele">
          <p>{{ $t('SURVEY_LIST_TABLE_COMPONENT_MODAL_TITLE') }}</p>
        </div>
        <div class="text-modal" v-html="$t('SURVEY_LIST_TABLE_COMPONENT_MODAL_CONTENT', { name: deleteQuizName })"></div>
      </div>
      <template #footer>
        <CButton class="cancel-btn" @click="warningModal = false" color="dark" variant="outline">{{
          $t('SURVEY_LIST_TABLE_COMPONENT_MODAL_BUTTON_CANCEL')
        }}</CButton>
        <CButton @click="del(id)" class="delete-btn">{{ $t('SURVEY_LIST_TABLE_COMPONENT_MODAL_BUTTON_DELETE') }}</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import StakingBadge from '@/components/StakingBadge';
import { Constants } from '@/constants';
import responseHelper from '@/utils/response-helper';
import stringHelper from '@/utils/string-helper';
import endpoints from '@/constants/endpoints';

export default {
  name: 'SurveyListTable',
  components: {
    StakingBadge,
  },
  data() {
    return {
      deleteQuizName: '',
      dateFormat: Constants.dateFormat,
      fields: [
        {
          key: '#',
          label: 'No.',
        },
        {
          key: 'status',
          label: this.$t('SURVEY_LIST_TABLE_COMPONENT_ACTIVE_COL'),
        },
        {
          key: 'start_date',
          label: this.$t('SURVEY_LIST_TABLE_COMPONENT_START_DATE_COL'),
        },
        {
          key: 'duration',
          label: this.$t('SURVEY_LIST_TABLE_COMPONENT_DURATION_COL'),
          _style: 'min-width: 80px; text-align: left;',
        },
        {
          key: 'end_date',
          label: this.$t('SURVEY_LIST_TABLE_COMPONENT_END_DATE_COL'),
          _style: 'min-width: 80px; text-align: left;',
        },
        {
          key: 'name',
          label: this.$t('SURVEY_LIST_TABLE_COMPONENT_NAME_COL'),
          _style: 'min-width: 80px; text-align: left;',
        },
        {
          key: 'silver_membership_point',
          label: this.$t('SURVEY_LIST_TABLE_COMPONENT_SILVER_COL'),
        },
        {
          key: 'gold_membership_point',
          label: this.$t('SURVEY_LIST_TABLE_COMPONENT_GOLD_COL'),
        },
        {
          key: 'platinum_membership_point',
          label: this.$t('SURVEY_LIST_TABLE_COMPONENT_PT_COL'),
        },
        {
          key: 'action',
          label: '',
        },
      ],
      warningModal: false,
      formatNumber: stringHelper.formatNumber,
    };
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    activePage: {
      type: Number,
      default: 0,
    },
    limit: {
      type: Number,
      default: 0,
    },
    isAllowedDel: {
      type: Boolean,
      default: false,
    },
    getSurveys: {
      type: Function,
      default: null,
    },
  },
  methods: {
    viewDetails(item,index) {
      const url = item.type === 'QUIZ' ? `ms-point/quizzes/${item.id}/details#${index}` : `ms-point/surveys/${item.id}/details#${index}`;

      this.$router.push(url);
    },
    async del(id) {
      try {
        await this.$http.delete(endpoints.deleteQuiz(id));
        this.$notify({
          group: 'notifications',
          type: 'success',
          title: this.$t('SURVEY_LIST_TABLE_COMPONENT_DELETE_NOTIFY_TITLE_SUCCESSFUL'),
          text: this.$t('SURVEY_LIST_TABLE_COMPONENT_DELETE_SUCCESSFUL', { name: this.deleteQuizName }),
        });
        this.warningModal = false;
        this.getSurveys();
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('SURVEY_LIST_TABLE_COMPONENT_NOTIFY_TITLE_ERROR'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    openModal(value) {
      this.id = value.id;
      this.deleteQuizName = value.name;
      this.warningModal = true;
    },
  },
};
</script>

<style lang="scss">
.survey-table-container {
  .survey-list-table-card {
    .table {
      margin-bottom: 0;
      .hand {
        cursor: pointer;
      }
      .btn {
        font-size: 14px;
        font-weight: 400;
        color: #0505a5;
        outline: none;
        padding-top: 0;
        padding-bottom: 0;
      }

      .survey-image {
        width: 30px;
      }

      .gold-membership-point {
        color: #d4af37;
      }

      .platinum-membership-point,
      .silver-membership-point {
        color: #a8a7ae;
      }

      .fixed-rate-icon {
        color: #5da30b;
      }
      .survey-cat,
      .survey-actived_flg {
        border: none;
        padding: 5px 0px;
        background-color: transparent;
      }
    }
  }
  .modal-body {
    padding: 1.3rem 1.5rem;
    .tit-dele {
      p {
        font-size: 18px;
      }
    }
  }

  .modal-content {
    width: 85%;
    .modal-header {
      border: none;
      padding: 0 8px;
      display: none;
    }
    .text-modal {
      strong {
        color: #3a4a7f;
        font-weight: 500;
      }
    }
    .modal-footer {
      border: none;
      justify-content: center;
      .delete-btn {
        background-color: #ff5555;
        color: #ffffff;
        width: 150px;
        font-weight: 500;
        font-size: 14px;
        margin: 0 10px;
      }
      .cancel-btn {
        width: 150px;
        border: 2px solid #cbcbcb;
        color: #657187;
        font-weight: 500;
        font-size: 14px;
        margin: 0 10px;
        &:hover {
          background-color: #fff;
        }
      }
    }
  }
}
</style>

