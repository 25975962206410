<template>
  <CRow class="survey-content">
    <CCol lg="12" md="12">
      <div class="surveys-container">
        <CCard>
          <CCardHeader>
            <CRow class="">
              <CCol lg="11" md="11">
                <div class="description" v-html="$t('MS_POINT_SETTING_COMPONENT_TAB_QUIZ_SURVEY_DESCRIPTION')"></div>
              </CCol>
              <CCol v-if="isAllowedUpdateSettingSurvey" lg="1" md="1">
                <div class="btn-switch-wrapper">
                  <label class="btn-switch" :class="[{'btn-switch-ja': locale == 'ja'}]">
                    <input @change="updateMsPointSettingSurvey()" type="checkbox" class="checkbox" v-model="isSurveyIsEnabled" />
                    <span class="check-silder"></span>
                  </label>
                </div>
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <CNav variant="pills">
              <CNavItem active>
                <div @click="onSearch()">{{ $t('MS_POINT_SETTING_COMPONENT_TAB_QUIZ_SURVEY_PENDDING') }}</div>
              </CNavItem>
              <CNavItem>
                <div @click="showHistory()">{{ $t('MS_POINT_SETTING_COMPONENT_TAB_QUIZ_SURVEY_HISTORY') }}</div>
              </CNavItem>
            </CNav>
            <SurveyListTable
              :limit="limit"
              :activePage="activePage"
              :items="items"
              :total="total"
              :isAllowedDel="isAllowedDel"
              :getSurveys="getSurveys"
            />
            <StakingPagination
              :limit="limit"
              :total="total"
              :activePage="activePage"
              @changePage="onChangePage"
              @changePageSize="onChangePageSize"
            />
            <CRow class="form-actions mt-3">
              <CCol lg="12" md="12" class="text-right">
                <CButton class="btn btn-add-new" @click="addNewSurvey()">+ {{ $t('SURVEY_LIST_PAGE_ADD_NEW') }}</CButton>
              </CCol>
            </CRow>

            <SelectNewSurveyTypeModal
              :isShownModal="isShownSelectSurveyTypeModal"
              @close-modal="closeModal"
              @create-quiz="createAQuiz"
              @create-survey="createASurvey"
            />
          </CCardBody>
        </CCard>
      </div>
    </CCol>
  </CRow>
</template>

<script>
import { mapGetters } from 'vuex';
import { endpoints, env, Constants } from '@/constants';
import SurveyListTable from '@/components/Survey/SurveyListTable';
import SearchSurveyHeader from '@/components/Survey/SearchSurveyHeader';
import SelectNewSurveyTypeModal from '@/components/Survey/SelectNewSurveyTypeModal';
import StakingPagination from '@/components/StakingPagination';
import stringHelper from '@/utils/string-helper';
import responseHelper from '@/utils/response-helper';
import dateTimeHelper from '@/utils/datetime-helper';

export default {
  name: 'SurveyList',
  components: {
    SurveyListTable,
    StakingPagination,
    SelectNewSurveyTypeModal,
  },
  data() {
    return {
      items: [],
      filterData: {},
      total: 0,
      limit: env.app.pageSize,
      activePage: 1,
      isAllowedDel: false,
      isSurveyIsEnabled: false,
      isAllowedUpdateSettingSurvey: false,
      isShowHistory: false,
      isShownSelectSurveyTypeModal: false,
    };
  },
  props: {
    msPointSurveyIsEnabled: {
      type: Boolean,
      default: () => null,
    },
  },
  computed: {
    offset() {
      return (this.activePage - 1) * this.limit;
    },
    pages() {
      return Math.ceil(this.total / this.limit);
    },
    ...mapGetters(['userPermissions','locale']),
  },
  async mounted() {
    this.isSurveyIsEnabled = this.msPointSurveyIsEnabled;
    this.checkPermissions();
    this.getSurveys();
    this.isLoading = false;
  },
  methods: {
    checkPermissions() {
      if (this.userPermissions.length > 0) {
        this.userPermissions.forEach((item) => {
          if (item.name === 'DELETE_SURVEY') {
            this.isAllowedDel = true;
          }
          if (item.name === 'UPDATE_MS_POINT_SETTINGS') {
            this.isAllowedUpdateSettingSurvey = true;
          }
        });
      }
    },
    async getSurveys(data) {
      this.isLoading = true;
      data = data || {};
      this.filterData = {
        ...this.filterData,
        ...data,
      };
      const filterData = this.filterData;

      try {
        const params = {
          limit: this.limit,
          offset: this.offset,
          name: filterData.keyword,
        };
        if (this.isShowHistory) {
          params.history = 1;
        }
        const result = await this.$http.get(endpoints.getQuizzes, { params });

        this.items = result.data.items || [];
        this.items.forEach(item => {
          item.duration = dateTimeHelper.getDurationTime(item.start_date, item.end_date);
        });
        this.total = result.data.total;
        this.isLoading = false;
        this.isShowHistory = false;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('SURVEY_LIST_PAGE_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async onChangePage(page) {
      this.activePage = page;

      this.getSurveys();
    },
    async onChangePageSize(value) {
      this.limit = value;
      this.activePage = 1;

      this.getSurveys();
    },
    async onSearch(searchData) {
      const data = searchData;
      this.activePage = 1;

      this.getSurveys(data);
    },
    addNewSurvey() {
      this.isShownSelectSurveyTypeModal = true;
    },
    closeModal() {
      this.isShownSelectSurveyTypeModal = false;
    },
    createAQuiz() {
      this.$router.push('ms-point/create-quiz');
      this.isShownSelectSurveyTypeModal = false;
    },
    createASurvey() {
      this.$router.push('ms-point/create-survey');
      this.isShownSelectSurveyTypeModal = false;
    },
    async updateMsPointSettingSurvey() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      try {
        const data = {
          ms_point_survey_is_enabled: this.isSurveyIsEnabled,
        };
        const result = await this.$http.put(endpoints.updateSurveySetting, data);

        this.$notify({
          group: 'notifications',
          type: 'success',
          title: this.$t('MEMBERSHIP_TYPES_PAGE_TITLE_NOTIFY_SUCCESS'),
          text: this.$t('MS_POINT_SETTING_PAGE_TEXT_NOTIFY_SUCCESS'),
        });

        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('MEMBERSHIP_TYPES_PAGE_TITLE_NOTIFY_ERROR'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    showHistory() {
      this.isShowHistory = true;
      this.onSearch();
    },
  },
};
</script>

<style lang="scss">
.surveys-container {
  // padding: 0 24px;
  border: 1px solid;
  border-radius: 0.25rem;
  background-color: #fff;
  border-color: #d8dbe0;
  @media screen and (max-width: 1024px) {
    padding: 0 15px;
  }

  .card {
    border: none;
    > .card-header {
      border-bottom: none;
      padding-bottom: 0px;

      h1 {
        color: #657187;
        font-size: 18px;
        font-weight: 600;
        line-height: 34px;
        margin-bottom: 0px;
      }
      .description {
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
        color: #8a93a2;
        strong {
          font-weight: bold;
        }
      }
    }

    > .card-body {
      .nav-pills .nav-link.active,
      .nav-pills .show > .nav-link {
        background-color: #8fb5f7;
      }
    }
    a {
      color: #8fb5f7;
    }

    .form-actions {
      .btn {
        &.btn-add-new {
          background: #3b4b7e;
          border: 1px solid #3b4b7e;
          box-sizing: border-box;
          border-radius: 18px;
          font-size: 14px;
          line-height: 14px;
          color: #ffffff;
          border: none;
          min-width: 200px;
        }
      }
    }
  }
}
</style>
