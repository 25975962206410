<template>
  <div class="search-survey-header-container">
    <ValidationObserver ref="form">
      <CRow>
        <CCol md="3" sm="12">
          <ValidationProvider name="SEARCH_SURVEY_HEADER_COMPONENT_FIELD_NAME_VALIDATE_DATE_RANGE" v-slot="{ errors }" rules>
            <div role="group" class="form-group">
              <label class>{{ $t('SEARCH_SURVEY_HEADER_COMPONENT_FIELD_NAME_VALIDATE_DATE_RANGE') }}</label>
              <div class="input-group1">
                <date-range-picker
                  v-model="searchData.dateRange"
                  :locale-data="dateRangeOption.localeData"
                  :always-show-calendars="dateRangeOption.alwaysShowCalendars"
                  :append-to-body="dateRangeOption.appendToBody"
                  :ranges="dateRangeOption.showRanges"
                  :auto-apply="dateRangeOption.autoApply"
                ></date-range-picker>
              </div>
            </div>
            <span class="error-msg-wrap">
              <span class="error-msg">{{ errors[0] }}</span>
            </span>
          </ValidationProvider>
        </CCol>

        <CCol md="3" sm="12">
          <ValidationProvider name="SEARCH_SURVEY_HEADER_COMPONENT_KEYWORD_FIELD_NAME" v-slot="{ errors }" rules="max:100">
            <CInput
              :label="$t('SEARCH_SURVEY_HEADER_COMPONENT_KEYWORD_FIELD_NAME')"
              type="text"
              v-model="searchData.keyword"
              class="form-group"
              @keyup.enter="search()"
            />
            <CRow>
              <CCol :md="12">
                <span class="error-msg-wrap">
                  <span class="error-msg">{{ errors[0] }}</span>
                </span>
              </CCol>
            </CRow>
          </ValidationProvider>
        </CCol>
      </CRow>
    </ValidationObserver>

    <CRow class="mt-2 form-actions">
      <div class="col-md-6 btn-disabled"></div>
      <CCol md="3" sm="12">
        <CButton class="btn btn-reset" @click="resetForm()">{{ $t('SEARCH_SURVEY_HEADER_COMPONENT_RESET_BUTTON') }}</CButton>
      </CCol>

      <CCol md="3" sm="12">
        <CButton class="btn btn-search" @click="search()">{{ $t('SEARCH_SURVEY_HEADER_COMPONENT_SEARCH_BUTTON') }}</CButton>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import moment from 'moment';
import { Constants } from '@/constants';
import stringHelper from '@/utils/string-helper';
import sessionStorageHelper from '@/utils/sessionStorage-helper';
import dateTimeHelper from '@/utils/datetime-helper';
import DateRangePicker from 'vue2-daterange-picker';

export default {
  name: 'SearchSurveyHeader',
  components: {
    DateRangePicker,
  },
  data() {
    return {
      searchData: sessionStorageHelper.getData(sessionStorageHelper.KEYS.FilterSurveys) || this.getDefaultValues(),
      dateRangeOption: {
        alwaysShowCalendars: true,
        appendToBody: false,
        showRanges: false,
        autoApply: false,
        localeData: {
          firstDay: 1,
          format: 'yyyy/mm/dd',
          direction: 'ltr',
          separator: ' - ',
          applyLabel: 'Apply',
          cancelLabel: 'Cancel',
          weekLabel: 'W',
        },
      },
      dateTimePickerFormat: Constants.dateTimePickerFormat,
    };
  },
  props: {
    onSearch: {
      type: Function,
      default: null,
    }
  },
  async mounted() {
    this.search();
  },
  methods: {
    getDefaultValues() {
      return {
        dateRange: {
          startDate: null,
          endDate: null,
        },
        keyword: '',
      };
    },
    resetForm() {
      this.searchData = this.getDefaultValues();

      this.search();
    },
    async search() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) {
        return;
      }

      this.searchData.dateRange.startDate = dateTimeHelper.startOfDate(this.searchData.dateRange.startDate);
      this.searchData.dateRange.endDate = dateTimeHelper.startOfDate(this.searchData.dateRange.endDate);
      sessionStorageHelper.setData(sessionStorageHelper.KEYS.FilterSurveys, this.searchData);

      const searchData = {
        ...this.searchData,
      };
      this.onSearch(searchData);
    },
  },
};
</script>>

<style lang="scss">
.search-survey-header-container {
  margin-bottom: 35px;
  .form-search {
    margin-bottom: 5px;
  }

  .form-actions {
    .btn {
      border: none;
      border-radius: 32px;
      height: 32px;
      padding: 0 30px;
      color: #fff;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 14px;
      text-align: center;
      border: 1px solid #1a2958;
      border-radius: 17.5px;
      background-color: #1a2958;
      width: 100%;
      display: block;

      &.btn-search {
        background: #3b4b7e;
      }

      &.btn-reset,
      &.btn-download-csv {
        color: #1a2958;
        background-color: #fff;
      }
    }
  }

  .vue-daterange-picker {
    width: 100%;
    .daterangepicker {
      position: absolute;
      top: 35px;
      left: 0;
      transform: unset;
      padding: 0;
      z-index: 99;
      &::after,
      &::before {
        left: 80px;
        right: unset;
      }
      .calendars-container {
        flex-wrap: wrap;
      }
    }
    .fa.fa-calendar {
      display: none;
    }
  }
}
</style>
