var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"survey-table-container mt-3"},[_c('CCard',{staticClass:"survey-list-table-card"},[_c('CDataTable',{attrs:{"items":_vm.items,"fields":_vm.fields,"fixed":"","hover":"","striped":"","bordered":""},scopedSlots:_vm._u([{key:"#",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('td',[_vm._v(_vm._s(index + 1))])]}},{key:"status",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-left"},[_c('StakingBadge',{staticClass:"no-border",attrs:{"type":"survey-status","text":item.status}})],1)]}},{key:"name",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('StakingBadge',{staticClass:"mr-2",attrs:{"type":"survey-type","text":item.type}}),_vm._v(" "+_vm._s(_vm._f("truncate")(item.name,30)))],1)]}},{key:"start_date",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm._f("moment")(item.start_date,_vm.dateFormat)))])]}},{key:"end_date",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm._f("moment")(item.end_date,_vm.dateFormat)))])]}},{key:"duration",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.duration))])]}},{key:"gold_membership_point",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('span',{staticClass:"gold-membership-point"},[_vm._v(_vm._s(_vm.formatNumber(item.gold_membership_point, '-')))])])]}},{key:"silver_membership_point",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('span',{staticClass:"silver-membership-point"},[_vm._v(_vm._s(_vm.formatNumber(item.silver_membership_point, '-')))])])]}},{key:"platinum_membership_point",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('span',{staticClass:"platinum-membership-point"},[_vm._v(_vm._s(_vm.formatNumber(item.platinum_membership_point, '-')))])])]}},{key:"action",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('td',{staticClass:"buttons",attrs:{"width":"120px"}},[_c('button',{staticClass:"btn btn-view-details",on:{"click":function($event){return _vm.viewDetails(item,index + 1)}}},[_c('img',{staticClass:"hand edit-icon",attrs:{"src":require("@/assets/images/ico-edit-user.svg"),"alt":""}})]),(_vm.isAllowedDel && item.name)?_c('CButton',{on:{"click":function($event){return _vm.openModal(item)}}},[_c('img',{staticClass:"hand edit-icon",attrs:{"src":require("@/assets/images/ico-del-user.svg"),"alt":""}})]):_vm._e()],1)]}}])})],1),_c('CModal',{attrs:{"id":"deleteModal","show":_vm.warningModal,"centered":true},on:{"update:show":function($event){_vm.warningModal=$event}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('CButtonClose',{on:{"click":function($event){_vm.warningModal = false}}})]},proxy:true},{key:"footer",fn:function(){return [_c('CButton',{staticClass:"cancel-btn",attrs:{"color":"dark","variant":"outline"},on:{"click":function($event){_vm.warningModal = false}}},[_vm._v(_vm._s(_vm.$t('SURVEY_LIST_TABLE_COMPONENT_MODAL_BUTTON_CANCEL')))]),_c('CButton',{staticClass:"delete-btn",on:{"click":function($event){return _vm.del(_vm.id)}}},[_vm._v(_vm._s(_vm.$t('SURVEY_LIST_TABLE_COMPONENT_MODAL_BUTTON_DELETE')))])]},proxy:true}])},[_c('div',{},[_c('div',{staticClass:"pb-4 tit-dele"},[_c('p',[_vm._v(_vm._s(_vm.$t('SURVEY_LIST_TABLE_COMPONENT_MODAL_TITLE')))])]),_c('div',{staticClass:"text-modal",domProps:{"innerHTML":_vm._s(_vm.$t('SURVEY_LIST_TABLE_COMPONENT_MODAL_CONTENT', { name: _vm.deleteQuizName }))}})])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }